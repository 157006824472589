$(document).ready(function(){
	$.validator.methods.phone = function( value, element ) {
		return this.optional( element ) || /^[0-9]{1}[0-9-.]+[0-9]{1}$/.test( value );
	}
	
	$("#main-form").validate({
		messages: {
			phone: {
				phone: 'Invalid phone number.'
			}
		}
	});

	$('.Btn--scroll').click(function(){
		$('html, body').animate({
			scrollTop: $( $.attr(this, 'href') ).offset().top
		}, 800);
	});

	var year = new Date().getFullYear();
	$("#year").html(year);
});